"use client";

import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/outline";

import { classNames, cn } from "@/lib/utils";
import { COMBOBOX_DATA_TESTID_TAG } from "@library/Combobox";

export const SELECT_TRIGGER_DATA_TESTID_TAG = "TAG_TO_FIND_SELECT-TRIGGER_COMPONENT";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    error?: boolean;
    size?: 20 | 24 | 32;
    hideIcon?: boolean;
  }
>(({ className, children, error, disabled, hideIcon = false, size, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    data-testid={SELECT_TRIGGER_DATA_TESTID_TAG}
    className={cn(
      // This is shadcn and our own styles poorly mixed together and overdetermined.
      "flex items-stretch justify-between overflow-hidden text-left outline-none",
      "text-oldgray-800",
      size === 20 ? "h-20 px-4 text-12-12" : size === 24 ? "h-24 px-8 text-12-16" : "h-32 px-12 text-13-18",
      error
        ? "border-red-600 focus-within:ring-oldred-200 data-[placeholder]:text-oldred-400 "
        : disabled
          ? "border-oldgray-300"
          : " border-oldgray-400 focus-within:border-oldblue-600 focus-within:ring-oldblue-200",
      disabled && "bg-oldgray-100 text-oldgray-500",
      "flex w-full items-center justify-between rounded border bg-transparent text-13-16 outline-0  focus:outline-none  disabled:cursor-not-allowed disabled:opacity-50 dark:border-oldgray-700 dark:text-oldgray-100 dark:focus:ring-oldgray-400 dark:focus:ring-offset-oldgray-900",
      "ring-0 transition-all focus-within:outline-none focus-within:ring focus-within:ring-opacity-40",
      className,
      " data-[placeholder]:font-mono data-[placeholder]:text-12-14",
    )}
    {...props}
  >
    <div className="flex w-full items-center justify-between">
      {children}
      {!hideIcon && (
        <ChevronDownIcon
          className={classNames(
            size === 20 ? " h-12 w-12" : size === 24 ? " h-16 w-16" : "h-20 w-20",
            "text-icon-base-3",
          )}
        />
      )}
    </div>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    contentContainerClassName?: string;
  }
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      position={"popper"}
      sideOffset={4}
      className={cn(
        // These two and "popper" make it appear as a dropdown with width of trigger
        "[width:var(--radix-select-trigger-width)]",
        "[max-height:var(--radix-select-content-available-height)]",
        // So we can use this in modals and drawers z-[>1000]
        "relative z-[1001] min-w-[8rem] overflow-hidden rounded bg-white text-oldgray-800 shadow-card-md animate-in fade-in-80 dark:border-oldgray-800 dark:bg-oldgray-800 dark:text-oldgray-400",
        className,
      )}
      {...props}
    >
      <SelectPrimitive.Viewport className="p-4">{children}</SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("py-6 pl-8 pr-8 text-sm font-bold text-oldgray-900 dark:text-oldgray-300", className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={classNames(
      "relative flex cursor-default select-none items-center rounded-sm py-6 pl-8 pr-16 text-13-18 outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:bg-gray-100 focus:bg-oldgray-200 dark:focus:bg-oldgray-700",
      className,
    )}
    {...props}
  >
    <span className="absolute right-8 flex h-[14px] w-[14px] items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="h-16 w-16" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-4 my-4 h-1 bg-oldgray-100 dark:bg-oldgray-700", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator };
