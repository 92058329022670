import Tooltip, { TooltipProps } from "@components/library/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/outline";

interface InfoTooltipProps extends Omit<TooltipProps, "children"> {}

export const InfoTooltip = ({ content, ...props }: InfoTooltipProps) => {
  if (!content) return null;
  return (
    <Tooltip delayDuration={200} keepOpenOnActivation content={content} {...props}>
      <InformationCircleIcon className="-my-2 inline-block h-16 w-16 text-icon-base-3 hover:text-icon-base-1" />
    </Tooltip>
  );
};
